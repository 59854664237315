enum FeatureType {
  BOOTH = 'Booth',
  WALL = 'Wall',
  ROOM = 'Room',
  ZONE = 'Zone',
  LARGE_PRODUCT = 'LargeProduct',
  SESSION = 'Session',
}

export default FeatureType;
