enum MapEvents {
  MAP_LOAD = 'map:load',
  MAP_CHANGE = 'map:change',
  MAP_UPDATE = 'map:update',
  MAP_ERROR = 'map:error',
  MAP_FULLSCREEN_ENTER = 'map:fullscreen:enter',
  MAP_FULLSCREEN_EXIT = 'map:fullscreen:exit',
  FEATURE_CLICK = 'feature:click',
  FEATURE_MOUSE_OVER = 'feature:mouse:over',
  FEATURE_MOUSE_OUT = 'feature:mouse:out',
  FEATURE_HOVER = 'feature:hover',
  MARKER_CLICK = 'marker:click',
  MARKER_MOUSE_OVER = 'marker:mouse:over',
  MARKER_MOUSE_OUT = 'marker:mouse:out',
}

export default MapEvents;
